import * as Sentry from '@sentry/nextjs'

export default function Custom404() {
  Sentry.captureMessage('404: Page not found')
  return (
    <>
      <h1>404 - Page Not Found</h1>
      <p>
        Unfortunately the page you are trying to access has been moved or no
        longer exists.
      </p>
      <p>
        Try going to our home page and look to see if you can find what you were
        looking for.
      </p>
    </>
  )
}
